body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html {
	/* both of these were added for the footer to work as expected */
	position: relative;
	min-height: 100%;
}

body {
	margin-bottom: 60px; /* added for footer */
	/*background-image: url("../images/y-so-serious-white.png");  background image for the pages */
	/* background-image: linear-gradient(135deg, rgba(234, 234, 234,0.06) 0%, rgba(234, 234, 234,0.06) 50%,rgba(169, 169, 169,0.06) 50%, rgba(169, 169, 169,0.06) 100%),linear-gradient(90deg, rgb(20,20,20),rgb(20,20,20)); background-size: 72px 72px; */
}

main {
	overflow: hidden; /* added for footer to work as expected */
}

.footer {
	/* This class is so the footer looks and acts correctly */
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px; /* Set the fixed height of the footer here */
	line-height: 60px; /* Vertically center the text there */
	background-color: #f5f5f5;
	text-align: center;
}

.hero {
	background-image: url('https://tuck-portfolio.s3-us-west-2.amazonaws.com/hero-night.jpg');
	height: calc(100vh - 60px);
	width: 100%;

	/* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://tuck-portfolio.s3-us-west-2.amazonaws.com/hero-night.jpg"); */

	/* Set a specific height */
	/* height: 50%; */

	/* Position and center the image to scale nicely on all screens */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

/* Place text in the middle of the image */
.hero-text {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
}

.text-dark.active {
	background-color: #343a40 !important;
	color: #f5f5f5 !important;
}
